.cls-1 {
    fill: var(--p);
}

#logoStacked {
    max-width: 100%;
    max-height: 30vh;
    height: 30vh;
    width: 100%;
    margin: 0;
    padding: 20px 0;
    margin-bottom: 20px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--p);
}

.login h2 {
    color: var(--p);
    font-size: 2rem;
    font-weight: 900;
}

.loginCont {
    padding: 5px 15px;
    width: auto!important;
}

.login input {
    text-align: left;
    width: 400px;
    padding: 10px;
    max-width: 100%;
}

.form {
    min-width: 85%;
    max-width: 90%;
    padding: 10px 5%;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 10px 0px;
    border-radius: var(--radius);
    border: var(--border);
    background-color: var(--l);
}
.form .b1 {
    align-items: flex-start;
    justify-content: flex-start;
}
.form .b2 {
    align-items: flex-start;
    justify-content: flex-start;
}
.formCont {
    text-align: left;
}
.formCont .b1 {
    margin: 0;
}
.formCont .b2 {
    max-width: 66%;
}
.login button {
    text-transform: none !important;
    padding: 0.4em 2.2em !important;
    border-radius: 50px;
    font-size: 1.1rem;
}
.loginTop {
    margin: 0;
}
a[href="/reset-password"] span {
    opacity: 0.8;
    margin-top: 20px;
}

@media screen and (max-width: 1080px) {
    .login input {
        font-size: 1rem;
        width: 300px;
    }
    .login h2 {
        font-size: 1.4rem;
    }
    .login button {
        font-size: .9rem;
    }
    .loginCont {
        padding: 5px 10px;
    }
}

@media screen and (max-width: 650px) {
    .loginCont {
        padding: 0px 5px;
    }
    .login input {
        padding: 3px;
        width: auto;
    }
}

@media screen and (max-height: 600px) {
    #logoStacked {
        max-height: 10vh;
        margin-bottom: 10px;
    }
    #app {
        margin-top: 5vh;
    }
}