* {
  font-size: 1rem;
  text-align: center;
  margin: 0;
  padding: 0;
}

*:not(i, .menu-arrow) {
  font-family: Arial, Helvetica, sans-serif;
}

:root {
  --l: #ffffff;
  --g: #f1ebe4;
  --d: #000200;
  --p: #ff9900;
  --radius: 12px;
  --border: .1rem solid var(--p);
  --transBorder: 2px solid transparent;
  --shadow: 1px 2px 3px #00000040;
  --transitionIn: 300ms ease-in;
  --transitionOut: 250ms ease-out;
}

*::-webkit-scrollbar {
  width: 5px;
  background-color: var(--g);
}

*::-webkit-scrollbar-track {
  /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2);*/
  width: 5px;
  background-color: var(--g);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--p);
  outline: 1px solid var(--l);
}

.warn {
  color: var(--p);
  font-weight: 800;
  text-decoration: underline;
}

.flasher {
  width: 100%;
  max-width: 100%;
  text-align: center;
}

.hidden {
  display: none !important;
}

logo-icon {
  width: 100%;
  max-width: 100%;
  max-height: 75px;
}

.ant-pagination-total-text {
  font-family: Arial, Helvetica, sans-serif !important;
}

.searchSection {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  background-color: var(--g);
  border-bottom: 1px solid var(--p);
  color: var(--d);
  margin-bottom: 5px;
  padding: 10px 5px;
}

.table {
  background-color: var(--l);
  padding: 5px;
  border-radius: var(--radius);
}

.afs {
  align-items: flex-start !important;
}

.table-responsive {
  overflow-y: visible;
  overflow-x: auto;
  max-width: 100%;
  min-width: 100%;
}

.searchList {
  padding: 5px;
  list-style: none;
}

.searchResult {
  background-color: var(--l);
  color: var(--d);
  padding: 5px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
}

.searchResult h3 {
  font-weight: 600;
  font-size: .9rem;
}

.searchResult button {
  font-size: .8rem;
  padding: 3px;
}

#searchbar {
  width: 100%;
  max-width: 100%;
  overflow: visible;
}

#searchresults {
  max-height: 30vh;
  overflow-y: auto;
  color: var(--l);
  background-color: var(--d);
  border-right: var(--border);
  border-left: var(--border);
  border-bottom: var(--border);
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  padding: 10px;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3px;
  animation: fade 300ms ease-in;
}

#searchbar input {
  font-size: .9rem;
  text-align: left;
  transition: var(--transitionIn);
  border-radius: var(--radius);
}

#searchbar input:focus {
  box-shadow: 0px 0px 20px var(--p);
  transition: var(--transitionOut);
}
a {
  color: inherit;
  text-decoration: none;
  transition: var(--transitionIn);
}
a:hover {
  color: var(--p)!important;
  cursor: pointer!important;
}
#logo {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  max-height: 100%;
}

#logo svg {
  max-width: 100%;
  max-height: 100%;
}

body {
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--d);
}

html {
  width: 100%;
  overflow-x: hidden;
}

#root {
  width: 100%;
}

.App {
  max-width: 100%;
  padding: 0;
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
  width: 100%;
  min-width: 100%;
  color: var(--d);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow-x: hidden;
  background-color: var(--l);
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: inherit;
}

.rel {
  position: relative;
}

.fs {
  justify-content: flex-start !important;
}

.b1 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  max-width: 100%;
}

.b2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-wrap: wrap;
}

table {
  width: 100%;
  max-width: 100%;
  padding: 20px 0;
  border-spacing: 0;
  border-collapse: collapse;
  display: table;
  box-sizing: border-box;
}

.bigbut {
  width: 80%;
  background-color: var(--d);
  color: var(--l)
}

.bigbut:hover {
  width: 80%;
  background-color: var(--p);
  color: var(--l);
}

tr {
  transition: var(--transitionIn);
}

#titleTable {
  background-color: var(--l);
}

#titleTable thead tr {
  cursor: default;
}

#titleTable tbody tr:hover {
  color: var(--l);
  background-color: var(--p);
  transition: var(--transitionOut);
}

td {
  padding: 8px;
  min-width: 15px;
  max-width: 100%;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid var(--l);
  cursor: default;
}

.datapoint {
  margin: 10px 0;
  padding: 12px;
  width: auto;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  background-color: var(--l);
}

.inners {
  background-color: var(--l);
}

.datapoint ul {
  max-width: 100%;
  list-style: none;
}

.datapoint h3 {
  font-size: 1rem;
  text-align: left;
  margin: 5px 0;
}

.datapoint ul li {
  font-size: .9rem;
  border-bottom: 1px solid var(--p);
  margin: 3px 0;
}
input, textarea{
  padding: .5rem
}
td input[type=checkbox] {
  cursor: pointer;
  width: 100%;
  min-height: 20px;
}

input[type=checkbox] {
  box-shadow: none;
}

td input[type=checkbox]:hover {
  cursor: pointer;
  width: 100%;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.tablebutton {
  margin: 0;
  padding: 3px 5px;
  font-size: .9rem;
  font-weight: 600;
}

#reportFields {
  width: 100%;
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100vh;
  background-color: #00000070;
  color: var(--l);
}

#reportholder {
  background-color: var(--l);
  color: var(--d);
  margin-top: 20vh;
  max-height: 60vh;
  overflow-y: scroll;
  border-bottom: var(--border);
  overflow-x: hidden;
  padding: 20px 0;
}

.bottom-right-float {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}

.bottom-left-float {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}

#AFF {
  max-width: 60%;
  min-width: 50%;
  padding: 5px 10px;
  background-color: var(--l);
  color: var(--d);
  margin-top: 20vh;
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  border-bottom: 2px inset var(--p);
}

.bottom-right-abs {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}

.top-right-abs {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.top-right-float {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1;
}

#buttons {
  padding: 20px 0;
  background-color: var(--d);
  background-image: url("https://testing.deliveryminds.com/static/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  max-width: 100%;
}

.reportButton {
  background-color: var(--l);
  color: var(--d);
}

.selectedButton {
  background-color: var(--p) !important;
  color: var(--l) !important;
}

.excel {
  display: table;
  empty-cells: show;
  border-collapse: collapse;
  border-spacing: initial;
  table-layout: auto;
  background-color: var(--l);
  border: var(--border);
  padding: 5px;
  white-space: nowrap;
  word-break: keep-all !important;
  word-wrap: normal !important;
}

.excel thead {
  border-bottom: 2px solid var(--d);
  max-height: 100%;
  padding: 5px 0;
}

.excel tbody {
  max-height: 100%;
}

.excel tr {
  line-height: 3px;
}

#spinner {
  width: 50px;
  height: 50px;
  border: 3px solid var(--p);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.flasher {
  position: fixed;
  bottom: 10px;
  right: 5px;
  visibility: visible;
  min-width: 50px;
  background-color: var(--p);
  color: var(--l);
  text-align: center;
  padding: 5px 10px;
  position: fixed;
  z-index: 100000;
  right: 10px;
  bottom: 10px;
  font-size: 17px;
  border-radius: var(--radius);
  animation: slideup 350ms;
  -webkit-animation: slideup 350ms;
  background-color: var(--p);
  color: var(--l);
  font-size: 1.2rem;
  font-weight: 800;
  width: auto;
  padding: 10px;
  max-width: 100%;
  margin: 0;
  animation: slideup 300ms ease;
  box-shadow: var(--shadow);
}

.excel thead tr th {
  border: 2px solid var(--d);
  padding: 3px;
  word-break: keep-all !important;
  word-wrap: normal !important;
  text-align: left;
  cursor: pointer;
}

.excel thead tr th strong {
  cursor: pointer;
}

.excel thead tr th .arrow {
  cursor: pointer;
}

.excel thead tr th {
  padding: 10px 5px;
}

select {
  min-width: 50%;
  max-width: 100%;
  border: var(--border);
  padding: 5px;
  border-radius: var(--radius);
  margin: 5px 0;
}

.homebutton {
  width: 30%;
  height: 20vh;
  max-width: 100%;
}

.excel tbody td {
  border: 1px solid var(--d);
  width: fit-content;
  text-align: left;
  padding: 3px;
  word-break: break-all !important;
  word-wrap: normal !important;
  white-space: nowrap;
}

.tableContain {
  width: 100%;
  max-width: 95%;
  max-height: 60vh;
  overflow-x: auto;
  overflow-y: auto;
}

#fixedtoggles {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  max-width: 100%;
  position: absolute;
  background-color: var(--d);
  width: auto;
  border: var(--border);
  border-radius: var(--radius);
  padding: 5px;
  margin: 0;
}

.fixedtoggle {
  background-color: var(--p);
  color: var(--l);
  margin: 3px;
  padding: 3px;
  font-size: .8rem;
}

.sidebarButton .sidebarContent button {
  color: var(--l)
}

.fixedtoggle.active {
  background-color: var(--l);
  color: var(--p);
}

.fixedtoggle.active:hover {
  background-color: var(--l);
  color: var(--d);
}

.formlet {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  min-width: 75%;
  max-width: 80%;
  margin: 2vh 10%;
  padding: 2.5%;
  border-radius: var(--radius);
  background-color: var(--g);
}

.afs {
  align-items: flex-start;
}

.suggestion {
  cursor: pointer;
  padding: 3px 5px;
  border-radius: 5px;
  border: var(--border);
  font-weight: 800;
  margin: 7px 0;
  transition: var(--transitionOut);
}

.suggestion:hover {
  background-color: var(--p);
  color: var(--l);
  transition: var(--transitionIn);
  box-shadow: var(--shadow);
}

.suggestionTitle {
  font-weight: 800;
  color: var(--p);
}

.suggestionTitle::before {
  content: 'Title: ';
  font-weight: 600;
  font-size: .9rem;
  color: var(--d);
}

.fI {
  min-width: 50%;
  border-radius: 0;
  border: none;
  box-shadow: var(--shadow);
  text-align: left;
  margin: 3px 0;
}

.homelabel {
  margin: 3px 0px;
  padding: 3px 0px;
}

.homeoption {
  text-align: left;
}

.checkInput {
  min-width: auto;
  width: 20px;
  margin: 5px;
}

.dateInput {
  width: 90%;
}

#selectedFields {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-radius: var(--radius);
  border: var(--border);
  min-width: 90%;
  min-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 100%;
  max-height: 60vh;
  padding: 10px;
  background-color: var(--d);
}

#selectedFields .field {
  background-color: var(--p);
  color: var(--l);
  padding: 5px 10px;
  transition: var(--transitionIn);
  border: 2px solid var(--l);
  max-width: 100%;
  cursor: grab;
  margin: 5px;
  border-radius: var(--radius);
}

#selectedFields .field:hover {
  background-color: var(--p);
  color: var(--l);
  background-color: var(--d);
  color: var(--l);
  transition: var(--transitionOut);
}

#selectedFields .field:active {
  cursor: grabbing;
}

#selectedFields:focus {
  background-color: var(--p);
}

.inners {
  margin: 10px;
  padding: 5px 10px;
  border: 1px solid var(--p);
  border-radius: var(--radius);
}

.inners select {
  margin: 10px 0;
}

.paycont {
  padding: 10px;
  max-width: 80%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  background-color: var(--g);
}

.paycont .b2 span {
  background-color: var(--l);
  border: 1px solid var(--p);
  padding: 3px 5px;
  margin: 10px;
  border-radius: 3px;
}

#fieldList {
  margin: 20px;
  border-radius: var(--radius);
  border: var(--border);
  min-width: 90%;
  min-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 100%;
  max-height: 60vh;
  padding: 10px 0;
  background-color: var(--d);
}

#fieldList .field {
  background-color: var(--p);
  color: var(--l);
  padding: 20px;
  transition: var(--transitionIn);
  border-top: 2px solid var(--l);
  border-bottom: 2px solid var(--l);
  max-width: 100%;
  cursor: grab;
  margin: 5px 0;
}

.field {
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 800;
  text-decoration: underline var(--l);
}

#fieldList .field:hover {
  background-color: var(--p);
  color: var(--l);
  background-color: var(--d);
  color: var(--l);
  transition: var(--transitionOut);
}

#fieldList .field:active {
  cursor: grabbing;
}

#fieldList:focus {
  background-color: var(--p);
}

.sele {
  background-color: var(--p) !important;
}

logo-icon {
  max-width: 90%;
  max-height: 50px;
}

.fable {
  font-size: 1rem;
  font-weight: 800;
  padding: 3px 5px;
  margin: 5px 0;
  color: var(--p);
  text-align: left;
  width: 100%;
  max-width: 100%;
}

.modal {
  width: 100%;
  height: 100vh;
  max-width: 100%;
  max-height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.modal-container {
  width: 100%;
  height: 100vh;
  max-width: 100%;
  max-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  background-color: rgba(0, 0, 0, 0.6);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fancyLabel {
  font-size: 1rem;
  font-weight: 800;
  cursor: pointer;
  border: 1px solid var(--d);
  border-radius: var(--radius);
  padding: 3px 5px;
  margin: 5px 0;
  transition: var(--transitionIn);
  --radius: 5px;
}

.fancyLabel:hover {
  color: var(--l);
  background-color: var(--p);
  transition: var(--transitionOut);
}

.jfs label {
  text-align: left;
}

.selectedItem {
  margin: 5px;
  padding: 3px;
  font-weight: 900;
  font-size: .8rem;
  border: var(--border);
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  cursor: default;
  margin-bottom: 10px;
}

#selectedFields .field {
  background-color: var(--p);
  color: var(--l);
  padding: 5px 10px;
  transition: var(--transitionIn);
  border: 2px solid var(--l);
  max-width: 100%;
  cursor: grab;
  margin: 5px;
  border-radius: var(--radius);
}

#selectedFields .field:hover {
  background-color: var(--p);
  color: var(--l);
  background-color: var(--d);
  color: var(--l);
  transition: var(--transitionOut);
}

#selectedFields .field:active {
  cursor: grabbing;
}

#selectedFields:focus {
  background-color: var(--p);
}

.inners {
  margin: 10px !important;
  padding: 5px 10px;
  border: 1px solid var(--p);
  border-radius: var(--radius);
}

.inners select {
  margin: 10px 0;
}

.paycont {
  padding: 10px;
  max-width: 80%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  background-color: var(--g);
}

.paycont .b2 span {
  background-color: var(--l);
  border: 1px solid var(--p);
  padding: 3px 5px;
  margin: 10px;
  border-radius: 3px;
}

#fieldList {
  margin: 20px;
  border-radius: var(--radius);
  border: var(--border);
  min-width: 90%;
  min-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 100%;
  max-height: 60vh;
  padding: 10px 0;
  background-color: var(--d);
}

#fieldList .field {
  background-color: var(--p);
  color: var(--l);
  padding: 20px;
  transition: var(--transitionIn);
  border-top: 2px solid var(--l);
  border-bottom: 2px solid var(--l);
  max-width: 100%;
  cursor: grab;
  margin: 5px 0;
}

.field {
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 800;
  text-decoration: underline var(--l);
}

#fieldList .field:hover {
  background-color: var(--p);
  color: var(--l);
  background-color: var(--d);
  color: var(--l);
  transition: var(--transitionOut);
}

#fieldList .field:active {
  cursor: grabbing;
}

#fieldList:focus {
  background-color: var(--p);
}

.b1 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
}

.b2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-wrap: wrap;
}

.c1 {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  grid-gap: 10px;
}

.c2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  grid-gap: 10px;
}

.c3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  grid-gap: 10px;
}

.c4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  grid-gap: 10px;
}

.jfe {
  justify-content: flex-end !important;
}

.wrap {
  flex-wrap: wrap;
  word-wrap: break-word;
}

.jfs {
  justify-content: flex-start !important;
}

#Reporting textarea {
  text-align: left;
  padding: 5px;
  max-width: 100%;
  width: 300px;
  margin: 3px 0;
  max-height: 50vh;
  height: auto;
  min-height: 5vh;
  border: 1px solid var(--p);
  border-radius: var(--radius);
}

#Reporting tr {
  transition: var(--transitionIn);
}

#Reporting td input[type=checkbox]:hover {
  cursor: pointer;
  width: 100%;
}

.status.online {
  position: absolute;
  bottom: 0;
  right: 0;
}

@keyframes menudrop {
  from {
    height: 0px;
  }

  to {
    height: 350px;
  }
}

#Reporting tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.tableContain {
  width: 100%;
  max-width: 95%;
  max-height: 60vh;
  overflow-x: auto;
  overflow-y: auto;
}

#loginForm {
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  margin-top: 20px;
  padding: 20px;
  width: auto;
  min-width: 60%;
  background-color: var(--l);
  border-bottom: var(--border);
}

#Reporting table {
  width: 100%;
  max-width: 100%;
  padding: 20px 0;
  border-spacing: 0;
  border-collapse: collapse;
  display: table;
  box-sizing: border-box;
}

@media screen and (min-width: 1300px) {
  .c4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1080px) {
  .c2 {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .c3 {
    grid-template-columns: 1fr 1fr;
  }

  .c4 {
    grid-template-columns: 1fr 1fr;
  }

  .homebutton {
    width: 100%;
  }
}

.arrow {
  max-width: 30px;
  max-height: 30px;
}

@media screen and (max-width: 650px) {
  input, textarea, select {
    font-size: .9rem;
  }

  .c3 {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .c2 {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .c4 {
    grid-template-columns: 1fr;
    justify-content: center;
  }
}

h1 {
  font-size: 2rem;
  max-width: 100%;
  padding: 10px;
  cursor: default;
  font-weight: 900;
}

h2 {
  font-size: 1.5rem;
  max-width: 100%;
  cursor: default;
  padding: 10px;
  font-weight: 900;
}

h3 {
  font-size: 1.2rem;
  max-width: 100%;
  cursor: default;
  padding: 5px;
  font-weight: 900;
}

span {
  font-weight: 400;
  max-width: 100%;
  cursor: default;
  padding: 5px;
}

p {
  font-weight: 400;
  max-width: 100%;
  cursor: default;
  padding: 5px;
  margin: 0;
}

label {
  font-size: .9rem;
  font-weight: 800;
  max-width: 100%;
  cursor: default;
  padding: 5px;
}
.w100 {
  width: 100%;
  max-width: 100%;
}
.align-text-left {
  text-align: left;
}
.pointer {
  cursor: pointer!important;
}
button {
  font-size: 1.1rem;
  padding: .1em .3em;
  margin: .65em;
  font-weight: 800;
  word-break: keep-all;
  text-transform: uppercase;
  border-radius: var(--radius);
  background-color: var(--p);
  color: var(--l);
  transition: var(--transitionIn);
  box-shadow: var(--shadow);
  border: 2px solid var(--p);
  --radius: 5px;
  cursor: pointer;
}

button:hover:not(.shine) {
  border: var(--border);
  transition: var(--transitionOut);
  color: var(--d);
  background-color: var(--l);
}

.jsb {
  justify-content: space-between;
}

.jfe {
  justify-content: flex-end;
}

.rotate {
  transition: var(--transitionIn);
  transform: rotate(180deg);
}

.spin {
  animation: spin 5s cubic-bezier(0.1, 0.3, 0.6, 0.9) infinite;
}

header {
  width: 100%;
  background-color: var(--d);
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 3fr;
  border-bottom: var(--border);
  overflow: visible;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100px;
}

#main {
  width: 100%;
  max-width: 100%;
  padding: 0 10px;
  padding-top: 125px;
  padding-bottom: 75px;
  position: relative;
  background-color: var(--g);
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
}

.mark {
  max-width: 100%;
  max-height: 20px;
}

#topAccountOptions:before {
  height: 40px;
  width: 2px;
  margin-right: 10px;
  background: #646D76;
  content: '';
  top: 10px;
  left: -17px;
}

#footer {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--d);
  color: var(--l);
  width: 100%;
  max-width: 100%;
  padding: 10px;
  border-top: var(--border);
  position: fixed;
  bottom: 0;
  left: 0;
  height: 65px;
  z-index: 1000;
}

#footer button {
  margin-right: 20px;
}

#sidebar {
  position: fixed;
  border-right: var(--border);
  top: 0;
  left: 0;
  z-index: 999;
  color: var(--l);
  background-color: var(--d);
  width: 60px;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  transition: var(--transitionIn);
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 125px;
  padding-bottom: 75px;
  padding-left: 10px;
  grid-gap: 20px;
}

#mobileMenu a {
  width: 100%;
  max-width: 100%;
  margin: 3px 0;
}

#mobileMenuCont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background-color: #00000060;
  z-index: 999;
}

#mobileMenu {
  position: fixed;
  border-right: var(--border);
  top: 0;
  left: 0;
  z-index: 999;
  color: var(--l);
  background-color: var(--d);
  height: 100%;
  max-height: 100%;
  width: max-content;
  overflow-y: auto;
  overflow-x: hidden;
  transition: var(--transitionIn);
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(5vh, 100px);
  padding-top: 75px;
  padding-bottom: 10px;
  padding-left: 10px;
  grid-gap: 20px;
}

#mobileMenu .b1 {
  padding: 10px 0;
  margin: 10px 0;
}

#mobileMenu button {
  padding: 5px;
}

#mobileMenu a {
  width: 100%;
  max-width: 100%;
  margin: 3px 0;
}

#sidebar a {
  width: 100%;
  max-width: 100%;
  margin: 3px 0;
}

.sidebarIcon {
  padding: 5px;
}

.sb {
  width: 100%;
  margin: 0;
  padding: 3px;
  max-width: 100%;
}

.sidebarContent {
  width: 60%;
}

#loginCont {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  max-width: 100%;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

#loginVideo {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}

ul {
  list-style: none;
}
.pr-list {
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}
.pr-list-item {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--p);
  padding: 10px 0 0 0;
}
.pr-list-button {
  font-size: .8rem;
  padding: .2rem .6rem;
  margin: .2rem 0;
}
.tInfo {
  text-align: left;
  background-color: var(--l);
  padding: 10px;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
}

.tInfo span {
  text-align: left;
}

.tInfo h4 {
  text-align: left;
}

.tInfo ul li {
  text-align: left;
}

.title-poster {
  margin: 5px;
  padding: 5px;
  max-width: 100%;
  max-height: 30vh;
  overflow: hidden;
  width: fit-content;
}
.title-poster-expanded {
  margin: 5px;
  padding: 5px;
  max-width: 80%;
  max-height: 80vh;
  overflow: hidden;
  width: fit-content;
}

span.totalled {
  background-color: var(--p);
}

#searchbar {
  width: 100%;
  max-width: 100%;
  overflow: visible;
}

.ant-table table {
  background-color: var(--l);
}

#searchresults {
  max-height: 30vh;
  overflow-y: auto;
  background-color: var(--d);
  border-right: var(--border);
  border-left: var(--border);
  border-bottom: var(--border);
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  padding: 10px;
  max-width: 100%;
  position: absolute;
  z-index: 1001;
  top: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3px;
  animation: slidedown 1s ease-in;
}

#searchbar input {
  width: 100%;
  max-width: 100%;
  font-size: 1.1rem;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: var(--transitionIn);
}

#searchbar input:focus {
  box-shadow: 0px 0px 20px var(--p);
  transition: var(--transitionOut);
}

#floatOpt {
  position: absolute;
  top: 100%;
  z-index: 5;
  right: 10px;
  width: 100%;
  max-height: 30vh;
  max-width: calc(100% - 20px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--d);
  color: var(--l);
  border-bottom: var(--border);
  border-right: var(--border);
  border-left: var(--border);
  border-bottom-right-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  transition: var(--transitionIn);
  /* animation: slideup 600ms ease; */
}

#loginHold {
  margin-top: 10%;
  max-width: 60%;
  padding: 10px 30px;
  box-shadow: 1px 1px 2px #00000080;
  background-color: var(--d);
  color: var(--l);
  border-radius: var(--radius);
  max-height: 90%;
  overflow-y: auto;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#loginHold .b1 {
  margin: 10px 0;
}

.fancyInput {
  font-size: .9rem;
  margin: 10px 0;
  padding: 5px;
  width: 100%;
  background-color: var(--d);
  color: var(--p);
  transition: var(--transitionIn);
}

.fancyInput:focus {
  color: var(--d);
  background-color: var(--l);
  border: var(--border);
  box-shadow: 0px 0px 30px var(--p);
  transition: var(--transitionOut);
}

.fancyLabel {
  color: var(--p);
  text-align: left;
  width: 100%;
  max-width: 100%;
  margin: 0;
  margin-bottom: -5px;
  padding: 0;
}

.jsfs {
  justify-self: flex-start !important;
}

#sidebar {
  transition: var(--transitionOut);
}

#sidebar:hover {
  width: min-content;
  box-shadow: 2px 3px 5px #00000040;
}

#sidebar:hover .sidebarButton .sidebarContent {
  display: block;
}

#sidebar .sidebarButton .sidebarContent {
  display: none;
  animation: size-left 300ms ease;
}

#sidebar button {
  background-color: transparent !important;
  border: none;
  margin: 5px 10px;
  word-break: keep-all;
}

.sidebarActive .sidebarIcon {
  color: var(--p);
}

.sidebarIcon {
  color: var(--l);
}

.selectTitle {
  padding: 10px;
  max-width: 100%;
  background-color: var(--l);
  color: var(--p);
  font-weight: 700;
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  height: 100px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transitionIn);
}

.selectTitle:hover {
  background-color: var(--d);
  border: 2px solid var(--p);
  color: var(--l);
  transition: var(--transitionOut);
}

.sidebarActive .sidebarContent button {
  background-color: transparent;
  border: var(--border) !important;
}

.poster {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover !important;
  background-position: 50% 50% !important;
  background-repeat: no-repeat !important;
  transition: 0.2s;
  background-color: #1A181C;
  box-shadow: 0px 0px 0px #1A181C inset;
}

header #mobileToggle {
  display: none;
}

.accordian {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--l);
  max-height: 100%;
  overflow: auto;
  height: fit-content;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  border-radius: var(--radius);
}

.accordian.active .accordian-header {
  border-bottom: 2px solid var(--p);
}

.accordian-header {
  width: 100%;
  max-width: 100%;
  padding: 10px;
  background-color: var(--g);
  color: var(--d);
  font-weight: 800;
  font-size: 1.2rem;
  cursor: pointer;
  text-align: left;
}

.barred {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.titled {
  padding-top: 10px !important
}

#titleTabs h2 {
  transition: var(--transitionOut);
  cursor: pointer;
}

#titleTabs h2:hover {
  text-decoration: underline;
}

.activeTab {
  color: var(--p);
}

.pad-10 {
  padding: 10px;
}

@media screen and (max-width: 1080px) {
  #main {
    padding-top: 125px;
  }

  header {
    grid-template-columns: 1fr 3fr 3fr;
  }

  header #takeAway {
    display: none;
  }
}

@media screen and (min-width: 850px) {
  #main {
    padding-left: 65px;
  }

  #title-header {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  header {
    grid-template-columns: 1fr 3fr;
    height: 60px;
    padding: 10px 0;
  }

  #main {
    padding-top: 75px;
    padding-bottom: 10px;
  }

  .c3 {
    grid-template-columns: 1fr;
  }

  header #takeAway {
    display: none;
  }

  header #topAccountOptions {
    display: none;
  }

  header #headSearch {
    display: none;
  }

  header #logo {
    display: none;
  }

  header #mobileToggle {
    color: var(--l);
    justify-content: flex-start;
    display: inline-flex;
    max-width: 100%;
    padding-left: 10px
  }

  #sidebar {
    display: none;
  }

  #footer {
    display: none;
  }

  .barred {
    padding: 0;
  }
}

@media screen and (max-width: 650px) {
  #main {
    padding-bottom: 15%;
  }
}

@media screen and (max-height: 600px) {
  #loginHold {
    margin-top: 10px;
  }
}

@keyframes spin {
  from {
    opacity: 1;
    transform: rotate(0deg);
  }

  25% {
    opacity: .5;
  }

  75% {
    opacity: 1;
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes size-left {
  from {
    opacity: 0;
    overflow-x: hidden;
  }

  25% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

.slidingText:hover {
  animation: runtext 5s cubic-bezier(0.55, 0.055, 0.87, 0.65) infinite;
}

.shine {
  overflow: hidden !important;
  position: relative;
}

.shine:after {
  background: #FFF;
  content: "";
  height: 155px;
  left: -75px;
  opacity: .2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 0s linear;
  width: 50px;
}

.shine:hover:after {
  left: 120%;
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}

@keyframes slideup {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes runtext {
  from {
    transform: translateX(0);
  }

  80% {
    opacity: 1;
  }

  83% {
    transform: translateX(600px);
    opacity: 0;
  }

  84% {
    transform: translateX(-600px);
    opacity: 0;
  }

  87% {
    opacity: 1;
  }

  to {
    transform: translateX(0);
  }
}